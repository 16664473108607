import payload_plugin_EK3ieJJSAr from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.3_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_1hVLiaNVu4 from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fvAt9tcCXW from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cZSBgCGdmj from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ajgktjBzau from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.0_sass@1.81_cstuqc4mkjsj2nfpmlg3gttlsq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_XQsxCRqlkv from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Orm91febQx from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vkXpGWWAmA from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_IRtlv41P9u from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_bDv3tRnjx1 from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.3_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/ilya/Sites/spilky-landing/.nuxt/components.plugin.mjs";
import prefetch_client_NltoOsAYHq from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._mlpcbt46kwyerwjhxw4jgnwsbe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_VjWwJOXELK from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.0_7ji5dezinw4kq36qigdeyz4lve/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_wem2bU1GWr from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.0_sass@1_rkvpqldtblwcu4qbpp32gqy6le/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_qqnAoNoVkR from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.0_sass@1_rkvpqldtblwcu4qbpp32gqy6le/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import _01_localization_Sjiymv6NFV from "/Users/ilya/Sites/spilky-landing/plugins/01.localization.ts";
import _02_widget_client_yEJB2c6tIw from "/Users/ilya/Sites/spilky-landing/plugins/02.widget.client.ts";
import _03_popupManager_or3NqQcoKq from "/Users/ilya/Sites/spilky-landing/plugins/03.popupManager.ts";
import defaults_NbyKP7wcYS from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.0_7ji5dezinw4kq36qigdeyz4lve/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_EK3ieJJSAr,
  revive_payload_client_1hVLiaNVu4,
  unhead_fvAt9tcCXW,
  router_cZSBgCGdmj,
  _0_siteConfig_ajgktjBzau,
  payload_client_XQsxCRqlkv,
  navigation_repaint_client_Orm91febQx,
  check_outdated_build_client_vkXpGWWAmA,
  chunk_reload_client_IRtlv41P9u,
  plugin_vue3_bDv3tRnjx1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NltoOsAYHq,
  titles_VjWwJOXELK,
  siteConfig_wem2bU1GWr,
  inferSeoMetaPlugin_qqnAoNoVkR,
  _01_localization_Sjiymv6NFV,
  _02_widget_client_yEJB2c6tIw,
  _03_popupManager_or3NqQcoKq,
  defaults_NbyKP7wcYS
]