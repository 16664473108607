<script
    lang="ts"
    setup
>
    import type { NuxtLinkProps } from '#app/components/nuxt-link'
    import { useLanguageStore } from '~/stores/language'

    type Props = NuxtLinkProps

    const props = withDefaults(defineProps<Props>(), {
        custom: undefined,
        replace: undefined,
        external: undefined,
        prefetch: undefined,
        noPrefetch: undefined,
        noRel: undefined,
    })

    const languageStore = useLanguageStore()

    const localizedTo = computed<NuxtLinkProps['to']>(() => {
        if (!props.to || !isObject(props.to)) {
            return props.to
        }

        const toParams = props.to.params || {}

        if (toParams.language) {
            if (toParams.language === languageStore.defaultLanguage) {
                toParams.language = ''
            }
        } else {
            toParams.language = (languageStore.language === languageStore.defaultLanguage)
                ? ''
                : languageStore.language
        }

        return {
            ...props.to,
            params: toParams,
        }
    })

    const customProps = computed<NuxtLinkProps>(() => ({
        ...props,
        to: localizedTo.value,
    }))
</script>

<template>
    <NuxtLink v-bind="customProps">
        <slot />
    </NuxtLink>
</template>
