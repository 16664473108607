import type { AnyFn } from '~/ts/types/common'

type Stack = 'menus' | 'modals'

type Item = {
    active: boolean
    callback: AnyFn
}

type RegisterOptions = {
    active?: boolean
}

type RegisterReturn = {
    id: string
    close: VoidFunction
    activate: VoidFunction
}

export class PopupManager {
    private menus: Map<string, Item> = new Map()
    private modals: Map<string, Item> = new Map()

    private registerItem(stack: Stack, callback: VoidFunction, options: RegisterOptions = {}): RegisterReturn {
        const {
            active = true,
        } = options

        const id = stringUtil.generateHash()

        this[stack].set(id, { active, callback })

        onScopeDispose(() => this.unregisterItem(stack, id))

        return {
            id,
            close: (...args) => this.closeItem(stack, id, args),
            activate: () => this.activateItem(stack, id),
        }
    }

    private unregisterItem(stack: Stack, id: string): void {
        this[stack].delete(id)
    }

    private activateItem = (stack: Stack, id: string): void => {
        const item = this[stack].get(id)

        if (!item) {
            return
        }

        item.active = true
    }

    private closeItem(stack: Stack, id: string, args = []): void {
        const item = this[stack].get(id)

        if (!item) {
            return
        }

        item.active = false

        item.callback(...args)
    }

    private closeLastItem(stack: Stack): void {
        if (!this[stack].size) {
            return
        }

        const entries = Array.from(this[stack].entries())

        for (let i = entries.length - 1; i >= 0; i--) {
            const [ id, value ] = entries[i]

            if (value.active) {
                this.closeItem(stack, id)

                return
            }
        }
    }

    private hasOpenItem = (stack: Stack): boolean => {
        const items = this[stack]

        if (!items.size) {
            return false
        }

        const values = Array.from(items.values())

        for (let i = 0, l = values.length; i < l; i++) {
            if (values[i].active) {
                return true
            }
        }

        return false
    }

    public registerMenu(callback: VoidFunction, options?: RegisterOptions): RegisterReturn {
        return this.registerItem('menus', callback, options)
    }

    public registerModal(callback: VoidFunction, options?: RegisterOptions): RegisterReturn {
        return this.registerItem('modals', callback, options)
    }

    public unregisterMenu(id: string): void {
        this.unregisterItem('menus', id)
    }

    public unregisterModal(id: string): void {
        this.unregisterItem('modals', id)
    }

    public isMenuOpen(): boolean {
        return this.hasOpenItem('menus')
    }

    public isModalOpen(): boolean {
        return this.hasOpenItem('modals')
    }

    public closeLastMenu(): void {
        this.closeLastItem('menus')
    }

    public closeLastModal(): void {
        this.closeLastItem('modals')
    }

    public closeLastMenuOrModal(): void {
        if (this.isMenuOpen()) {
            this.closeLastMenu()
        } else {
            this.closeLastModal()
        }
    }
}
