import type { RouterConfig } from '@nuxt/schema'
import { SCROLL_CONTAINER_ID } from '~/constants/app'

export const scrollBehaviorInteractionNumber = ref<number>(0)

// https://router.vuejs.org/guide/advanced/scroll-behavior.html
export default <RouterConfig>{
    scrollBehavior: (to, from, savedPosition) => {
        scrollBehaviorInteractionNumber.value++

        const scrollToTop = (fast = false): void => {
            document.querySelector(`#${ SCROLL_CONTAINER_ID }`)?.scrollTo({
                behavior: fast ? 'auto' : 'smooth',
                top: savedPosition?.top || 0,
                left: savedPosition?.left || 0,
            })
        }

        if (to.hash) {
            setTimeout(() => {
                document.querySelector(to.hash)?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }, (scrollBehaviorInteractionNumber.value === 1) ? 200 : 0)

            return
        }

        if (to.name === from.name) {
            return scrollToTop()
        }

        scrollToTop(true)
    },
}
