import type { UnwrapRef } from 'vue'
import type { AnyFn } from '~/ts/types/common'

export const throttleFn = (fn: AnyFn, delay: number): AnyFn<void> => {
    let isThrottled = false
    let lastArgs: any

    const timer = useTimer(() => {
        isThrottled = false

        if (lastArgs) {
            handler(...lastArgs)

            lastArgs = undefined
        }
    }, delay)

    const handler = (...args: any[]): void => {
        if (isThrottled) {
            lastArgs = args

            return
        }

        isThrottled = true

        fn(...args)

        timer.start()
    }

    return handler
}

export const throttleRef = <T>(initialValue: T, delay: number): Ref<UnwrapRef<T>> => {
    const state = ref<T>(initialValue)

    return customRef((track, trigger) => ({
        get(): T {
            track()

            return state.value as T
        },
        set: throttleFn((value: any) => {
            state.value = value

            trigger()
        }, delay),
    }))
}
