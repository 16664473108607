export default defineNuxtPlugin({
    name: 'widget',
    parallel: true,
    hooks: {
        'app:created'(): void {
            if (useError().value) {
                return
            }

            const { public: { apiUrl, widgetSupportCode } } = useRuntimeConfig()

            useHead({
                script: [
                    {
                        innerHTML: `
                            (function (w, d, k) {
                                w[k] = w[k] || function (...args) {
                                    (w[k].q = w[k].q || []).push(args);
                                };
                                var s = d.createElement('script');
                                s.type = 'text/javascript';
                                s.async = true;
                                s.src = '${ apiUrl }/widget/sdk/${ widgetSupportCode }';
                                d.getElementsByTagName('head')[0].appendChild(s)
                            })(window, document, 'SpilkySDK');
                            
                            window.SpilkySDK('setZIndex', 40)
                        `,
                        async: true,
                    },
                ],
            })
        },
    },
})
