<script
    lang="ts"
    setup
>
    const { public: { siteName, siteUrl } } = useRuntimeConfig()
    const { mobile, tablet, desktop, maxTablet, maxDesktop } = useWindowSize()
    const { language, locale, t, supportedLanguages } = useLang()
    const route = useRoute()

    const seoDescription = computed<string>(() => t('seo-meta-description'))
    const seoImage = computed<string>(() => `${ siteUrl }/img/seo-meta-${ language }.png`)

    // Статичні дані
    useServerSeoMeta({
        applicationName: siteName,
        ogSiteName: siteName,
        themeColor: '#f6f7f8',
    })

    // Динамічні/реактивні дані
    useSeoMeta({
        ogUrl: () => siteUrl + route.path,
        ogLocale: () => locale.nativeLocale,
        titleTemplate: (titleChunk: string) => {
            if (
                !titleChunk
                || [ siteName.toLowerCase(), ...supportedLanguages ].includes(titleChunk.toLowerCase())
            ) {
                return siteName
            }

            return `${ titleChunk } — ${ siteName }`
        },
    })

    useSchemaOrg([
        defineWebSite({
            description: seoDescription,
            url: siteUrl,
            inLanguage: () => locale.nativeLocale,
            workTranslation: [
                { '@id': siteUrl + '/en#website' },
                { '@id': siteUrl + '/ru#website' },
            ],
        }),
        defineOrganization({
            name: siteName,
            url: siteUrl,
            logo: () => seoImage.value,
            sameAs: [], // TODO посилання на соц. мережі
        }),
    ])

    useHead({
        link: [
            {
                rel: 'icon',
                type: 'image/x-icon',
                href: '/favicon.ico',
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
                href: '/favicon-16x16.png',
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
                href: '/favicon-32x32.png',
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '192x192',
                href: '/favicon-192x192.png',
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '512x512',
                href: '/favicon-512x512.png',
            },
            {
                rel: 'apple-touch-icon',
                type: 'image/png',
                href: '/apple-touch-icon.png',
            },
        ],
    })
</script>

<template>
    <Html :lang="language">
        <NuxtLayout>
            

            <NuxtPage />
        </NuxtLayout>
    </Html>
</template>
