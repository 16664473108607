import type { AnyFn, MaybeRef } from '~/ts/types/common'

type Options = boolean | AddEventListenerOptions

type TargetValue = HTMLElement | Window | Document

export const onEvent = (
    target: MaybeRef<TargetValue> | (() => TargetValue),
    event: string,
    listener: AnyFn,
    options: Options = false,
): VoidFunction => {
    if (!import.meta.client) {
        return noop
    }

    if (isFunction(target)) {
        target = target()

        if (!target) {
            return noop
        }
    }

    if (!isRef(target) && !target) {
        return noop
    }

    let cleanup = noop

    const register = (el, options) => {
        el.addEventListener(event, listener, options)

        return () => el.removeEventListener(event, listener, options)
    }

    const stopWatch = watch(
        () => [ unrefElement(target as any), options ],
        ([ el, options ]) => {
            cleanup()

            if (!el) {
                return
            }

            cleanup = register(el, options)
        },
        {
            immediate: true,
            flush: 'post',
        },
    )

    const stop = () => {
        stopWatch()
        cleanup()
    }

    tryOnScopeDispose(stop)

    return stop
}
