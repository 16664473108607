import type { AnyFn } from '~/ts/types/common'

export type EventHook<T = any> = {
    on: (fn: ((payload?: T) => void) | AnyFn) => VoidFunction
    off: (fn: VoidFunction) => void
    trigger: (...payload: T[]) => Promise<unknown[]>
    clear: VoidFunction
    getSize: () => number
}

export const createEventHook = <T = any>(): EventHook<T> => {
    const fns = new Set<AnyFn>()

    const off = (fn: AnyFn): void => {
        fns.delete(fn)
    }

    const on = (fn: AnyFn): VoidFunction => {
        fns.add(fn)

        tryOnScopeDispose(() => off(fn))

        return () => off(fn)
    }

    const trigger = (...args): Promise<unknown[]> => {
        return Promise.all(Array.from(fns).map(fn => fn(...args)))
    }

    const clear = (): void => fns.clear()

    const getSize = () => fns.size

    return {
        on,
        off,
        trigger,
        clear,
        getSize,
    }
}
