<script
    lang="ts"
    setup
>
    import type { FetchError } from 'ofetch'
    import type { NuxtError } from '#app'
    import { langToolT } from '~/helpers/languageTools'
    import en from '~/locales/en.json'

    type Props = {
        error: NuxtError | FetchError | Error | any
    }

    const DEFAULT_ERROR_STATUS_CODE = 500
    const TRANSLATED_ERROR_CODES = [ 403, 404, 500 ]

    const props = defineProps<Props>()

    const statusCode = computed<number>(() => +props.error?.statusCode || DEFAULT_ERROR_STATUS_CODE)

    const tEN = langToolT.bind(null, { language: 'en', defaultLanguage: 'en' }, { en })

    const errorDescription = computed<string>(() => {
        if (TRANSLATED_ERROR_CODES.includes(statusCode.value)) {
            return tEN(`error-${ statusCode.value }-description`)
        }

        return tEN(`error-${ DEFAULT_ERROR_STATUS_CODE }-description`)
    })

    const errorMessage = computed<string>(() => {
        if (TRANSLATED_ERROR_CODES.includes(statusCode.value)) {
            return tEN(`error-${ statusCode.value }-message`)
        }

        return tEN(`error-${ DEFAULT_ERROR_STATUS_CODE }-message`)
    })

    const goBack = (): void => {
        location.replace('/')
    }
</script>

<template>
    <div class="flex flex-col items-center h-full bg-light-stone text-black">
        <Title>
            {{ errorDescription }}
        </Title>

        <AppLink
            :to="{ name: 'index' }"
            class="app-link"
        >
            <AppBranding
                size="80"
                color="#000"
                class="my-12"
            />
        </AppLink>

        <div class="flex flex-col my-10 items-center">
            <div class="text-[80px] font-medium">
                {{ statusCode }}
            </div>

            <div class="text-[40px] font-medium">
                {{ errorDescription }}
            </div>

            <div class="text-[16px] mt-[36px]">
                {{ errorMessage }}
            </div>
        </div>

        <AppButton
            class="mt-5"
            @click="goBack()"
        >
            <AppIconArrowLeft
                size="16"
                color="#000"
                class="mr-2"
            />

            {{ tEN('back') }}
        </AppButton>
    </div>
</template>
