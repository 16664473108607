import type { Language } from '~/ts/types/language'
import { useLanguageStore } from '~/stores/language'

export default defineNuxtRouteMiddleware((to, from) => {
    if (useError().value) {
        return
    }

    const toLang = to.params.language as Language
    const fromLang = from.params.language as Language
    const { defaultLanguage, isSupportedLanguage } = useLanguageStore()

    if (toLang) {
        // Очищення мови, якщо була явно встановлена мова за замовчуванням
        if (toLang === defaultLanguage) {
            to.params.language = ''

            return to
        } else if (!isSupportedLanguage(toLang)) {
            // Помилка, якщо мова не підтримується
            return abortNavigation()
        }
    } else if (
        toLang === undefined
        && fromLang
        && (fromLang !== defaultLanguage)
    ) {
        // Встановлення попередньої мови, якщо це перехід через навігацію
        // Коректно працює у випадку навігації з використанням RouteLocationNamedRaw, тоді явно невказаний параметр language буде undefined (в іншому випадку може бути - '')
        to.params.language = fromLang

        return to
    }
})
