type GetClientContext = {
    window: Window | undefined
    document: Document | undefined
    location: Location | undefined
    navigator: Navigator | undefined
}

export const getClientContext = (): GetClientContext => {
    if (import.meta.client) {
        return {
            window,
            document: window.document,
            location: window.location,
            navigator: window.navigator,
        }
    }

    return {
        window: undefined,
        document: undefined,
        location: undefined,
        navigator: undefined,
    }
}
