<script
    lang="ts"
    setup
>
    import type { FetchError } from 'ofetch'
    import type { NuxtError } from '#app'

    type Props = {
        error: NuxtError | FetchError | Error | any
    }

    const props = defineProps<Props>()

    const statusCode = computed<number>(() => +props.error?.statusCode || 500)

    useSeoMeta({
        robots: false,
    })
</script>

<template>
    <ErrorPage :error="{ statusCode: statusCode }">
        {{ statusCode }}
    </ErrorPage>
</template>
