<template>
    <AppIcon
        v-bind="$props"
        :class="[ $style['loading'], { [$style['loading--animate']]: $attrs['animate'] } ]"
    >
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M12 4V2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12H4C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>

<style
    lang="sass"
    module
    scoped
>
    .loading
        position: relative

        &--animate
            animation-name: spin
            animation-duration: 500ms
            animation-iteration-count: infinite
            animation-timing-function: linear

            @keyframes spin
                from
                    transform: rotate(0deg)

                to
                    transform: rotate(360deg)
</style>
